<template>
  <div class="pt-4 pb-5">
    <h1 class="dark-text font-weight-semi-bold fz-28 px-4 px-md-0">Call flow</h1>

    <div class="d-flex flex-wrap mb-4 px-3 px-md-0">
      <!-- <v-btn to="/buy-a-number" text class="text-none d-flex align-center px-0 mr-6" height="36px">
        <v-icon size="22px" color="#919EAB" class="mr-1">add</v-icon>
        Buy new number
      </v-btn> -->

      <v-btn to="/callflow" text class="text-none d-flex align-center px-0 mr-6" height="36px">
        <v-icon size="22px" color="#919EAB" class="mr-1">call_split</v-icon>
        Callflows
      </v-btn>

<!--      <MoreActionComponent/>-->
    </div>

    <v-card class="pa-0 overflow-hidden" :loading="loading">
      <v-list v-if="numbers.length" class="pa-0">
        <template>
          <v-list-group
            v-for="(number, index) in numbers"
            :key="index"
            v-model="number.active"
            style="border-bottom: 1px solid #DFE3E8;"
          >
            <template v-slot:activator>
              <v-list-item-content class="py-4">
                <v-list-item-title class="fz-18 d-flex">
                  {{ number.number }}

                  <v-icon color="#ED6347" class="ml-1" v-if="number.block">block</v-icon>
                </v-list-item-title>

                <v-list-item-subtitle
                  class="py-1"
                  style="color: #637381;"
                >
                  {{ number.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="flex-row align-center v-list-item__action--hover ma-0">
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mx-1"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="assignUser(assignUserPopUp = !assignUserPopUp)"
                    >
                      <v-icon size="22px" color="#919EAB">person_add</v-icon>
                    </v-btn>
                  </template>
                  <span>Assign user</span>
                </v-tooltip>

                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="mx-1"
                      height="36px"
                      @click.stop="assignCallflow = true"
                    >
                      <v-icon color="#919EAB">call_split</v-icon>
                    </v-btn>
                  </template>
                  <span>Add callflow</span>
                </v-tooltip>

                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mx-1"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="block(index)"
                    >
                      <v-icon size="22px" color="#919EAB">block</v-icon>
                    </v-btn>
                  </template>
                  <span>Block</span>
                </v-tooltip>

                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mx-1"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="22px" color="#919EAB">edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mx-1"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="openDialog(index)"
                    >
                      <v-icon size="22px" color="#919EAB">delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove</span>
                </v-tooltip>
              </v-list-item-action>
            </template>

            <v-data-table
              v-ripple="false"
              v-model="number.checkedMembers"
              :headers="headers"
              :items="number.members"
              item-key="extension"
              show-select
              hide-default-footer
              class="v-data-table--custom pt-2"
              :header-props="{sortIcon: 'arrow_drop_down'}"
            >
              <template v-slot:[`header.data-table-select`]="{ props, on }">
                <v-simple-checkbox
                  :value="props.value || props.indeterminate"
                  v-on="on"
                  on-icon="check"
                  indeterminate-icon="remove"
                  off-icon=""
                  :indeterminate="props.indeterminate"
                  color="primary"
                />
              </template>

              <template v-slot:item.data-table-select="{ isSelected, select }">
                <v-simple-checkbox
                  v-ripple="{ 'center': true }"
                  :value="isSelected"
                  @input="select($event)"
                  on-icon="check"
                  indeterminate-icon="remove"
                  off-icon=""
                />
              </template>

              <template v-slot:item.status="{ item }">
                <v-row class="mr-0 d-flex align-center">
                  <v-col
                    col="12"
                    lg="5"
                    md="7"
                    class="py-0"
                  >
                    <v-chip
                      small
                      :class="{
                      'green lighten-3': item.status === true,
                      'red lighten-4': item.status === false,
                  }"
                      class="text-capitalize"
                    >
                      {{ item.status === true ? 'Active' : 'Disabled' }}
                    </v-chip>
                  </v-col>

                  <v-col
                    col="12"
                    class="d-flex justify-end align-center py-0"
                  >
                    <v-list-item-action
                      class="flex-row align-center justify-end v-list-item__action--normal mr-1 my-0"
                    >
                      <v-icon color="#ED6347" class="ml-1" v-if="!item.status">block</v-icon>
                    </v-list-item-action>

                    <v-list-item-action
                      class="flex-row align-center justify-end v-list-item__action--hover v-list-item__action--absolute ma-0"
                    >
                      <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            height="36px"
                          >
                            <v-icon color="#919EAB">call_split</v-icon>
                          </v-btn>
                        </template>
                        <span>Add callflow</span>
                      </v-tooltip>

                      <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            height="36px"
                            @click="blockMember(index)"
                          >
                            <v-icon color="#919EAB">block</v-icon>
                          </v-btn>
                        </template>
                        <span>Block</span>
                      </v-tooltip>

                      <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            height="36px"
                            @click="removeMember(index)"
                          >
                            <v-icon color="#919EAB">delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-list-group>
        </template>
      </v-list>
      <v-card-text v-else>Numbers empty!</v-card-text>
      <v-dialog
        persistent
        v-model="deleteDialog"
        :max-width="$vuetify.breakpoint.mdAndUp ? '350' : '260'"
      >
        <v-card>
          <v-card-title class="font-weight-bold">
            Delete number
          </v-card-title>

          <v-card-text>
            Are you sure you want to delete number?
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-md-end">

            <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
                class="mx-2">
              <v-btn
                color="secondary"
                outlined
                @click="deleteDialog = false"
                class="w-100 text-capitalize"
              >
                Cancel
              </v-btn>
            </div>

            <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
                class="mx-2">
              <v-btn
                depressed
                color="primary"
                @click="remove"
                class="w-100 text-capitalize"
              >
                Delete
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <assign-callflow :dialog="assignCallflow" @close="assignCallflow = false"/>
  </div>
</template>

<script>
import AssignCallflow from '@/components/AssignCallflow'
// import MoreActionComponent from '@/components/MoreActionComponent'
import { mapState } from 'vuex'

export default {
  name: 'Numbers',
  components: { AssignCallflow },
  data: () => ({
    assignCallflow: false,
    headers: [
      {
        text: 'Extension',
        align: 'start',
        sortable: true,
        value: 'extension'
      },
      {
        text: 'Assigned member',
        align: 'start',
        sortable: true,
        value: 'member'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'status'
      }
    ],
    numbers: [],
    loading: false,
    deleteDialog: false,
    selectedNumber: null
  }),
  computed: {
    ...mapState('components', [
      'rightPopUpMyContacts'
    ]),
    assignUserPopUp: {
      get () {
        return this.rightPopUpMyContacts
      },
      set (videoValue) {
        return videoValue
      }
    }
  },
  created () {
    this.getNumbersList()
  },
  methods: {
    async getNumbersList () {
      this.loading = true

      await this.$store
        .dispatch('didNumbersList')
        .then((res) => {
          console.log(res, 'dara')
          this.numbers = res.data.didList.map((item, i) => ({
            ...item,
            subtitle: `Assigned to ${i} member`
          }))

          this.loading = false
        }).catch(err => {
          console.log('err', err)
        })
    },
    remove: function () {
      this.deleteDialog = false
      this.numbers.splice(this.selectedNumber, 1)
    },
    block: function (index) {
      this.numbers[index].block = !this.numbers[index].block
      console.log(this.numbers[index].block)
    },
    blockMember: function (index, memberIndex) {
      this.numbers[index].members[memberIndex].status = !this.numbers[index].members[memberIndex].status
    },
    removeMember: function (index, memberIndex) {
      this.numbers[index].members.splice(memberIndex, 1)
    },
    assignUser (payload) {
      this.$store.dispatch('components/RightPopUpMyContacts', payload)
    },
    openDialog (index) {
      this.selectedNumber = index
      this.deleteDialog = true
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .v-list-item:hover .v-list-group__header__append-icon {
    display: none !important;
  }
</style>
