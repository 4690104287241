<template>
  <div class="pt-4 main">
    <div class="d-flex justify-space-between align-center">
      <h1 class="fz-28 dark-text font-weight-medium mb-5 px-4 px-md-0">
        Phone numbers
      </h1>
      <v-select
        v-model="pageTotal"
        :items="pageTotalItems"
        class="pt-0 page-select"
        menu-props="offsetY"
        solo
        outlined
        hide-details
      >
        <template v-slot:append>
          <v-icon size="15px">unfold_more</v-icon>
        </template>
      </v-select>
    </div>
    <v-card class="fz-14 mb-5" :loading="loading">
      <v-list class="py-0">
        <v-list-item class="py-1 d-flex flex-wrap">
          <v-list-item-content>
            <v-list-item-subtitle class="d-flex align-center fz-14">
              <v-checkbox v-model="selectAll" color="primary" hide-details class="mt-0 ml-2"></v-checkbox>
              <span class="ml-2">All Numbers</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="flex-row justify-end align-end" style="width: auto">
            <!-- <v-btn :disabled="!selectedItems.length" class="violet-button mr-4 my-1" @click.stop="releaseDialog = true">
              Release selected
            </v-btn> -->
            <v-btn
              :disabled="!selectedItems.length"
              class="violet-button mr-4 my-1"
              @click.stop="extendDialog = true"
            >
              Extend selected
            </v-btn>
            <v-btn class="violet-button mr-4 my-1" @click="$router.push({ name: 'BuyNewNumber' })">
              Buy Numbers
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <div v-if="!items.length" class="d-flex justify-center pa-4 font-weight-bold">
          <span>No phone numbers</span>
        </div>
        <div v-else>
          <template v-for="(item, itemIndex) in paginationList">
            <v-divider v-if="itemIndex" :key="itemIndex + 'divider'"></v-divider>
            <v-list-item :key="itemIndex" class="px-2 px-sm-5 py-2 d-flex flex-wrap">

              <v-list-item-avatar>
                <v-checkbox v-model="item.isSelected" color="primary" hide-details class="mt-0"
                  @change="selectItem(item)"></v-checkbox>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fz-14 font-weight-semi-bold">
                  {{ item.number }}
                </v-list-item-title>

                <v-list-item-subtitle class="fz-14">
                  Number expiration date: {{ formatDate(item.expireOn) }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="flex-row align-center">
                <v-btn class="violet-button mr-4" @click.stop="openReleaseDialog(item.number)">
                  Release
                </v-btn>
                <v-btn class="grey-button mr-2" @click.stop="openExtendDialog(item.number)">
                  Extend
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </div>
        <v-divider />
        <div v-if="items.length" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex">
            <template v-for="index in countPage">
              <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0" />
              <v-btn :class="{ 'grey-button--selected': index === page }" text tile min-width="56" class="" :key="index"
                @click="pagination(index)">{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex">
            <v-btn text tile class="px-0" min-width="36" width="36px" @click="previousPage(page)">
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical />
            <v-btn text tile min-width="36" class="light-text px-0" width="36px" @click="nextPage(page)">
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-list>

      <v-dialog persistent v-model="releaseDialog" max-width="450px">
        <v-card>
          <v-card-title class="font-weight-bold">
            Release Number
          </v-card-title>
          <v-card-subtitle>
            Select a release period
          </v-card-subtitle>
          <v-card-text>
            <v-radio-group v-model="releaseRadio" mandatory>
              <v-radio label="Release Now" value="radio-1"></v-radio>
              <!-- <v-radio
                  label="Release at the expiration date"
                  value="radio-2"
                ></v-radio> -->
            </v-radio-group>
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-space-between">
            <v-btn depressed class="text-capitalize" @click="closeRelease">
              Cancel
            </v-btn>
            <v-btn depressed color="primary" class="text-capitalize" @click="submitRelease">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="extendDialog" max-width="450px">
        <v-card>
          <v-card-title class="font-weight-bold">
            Extend Number
          </v-card-title>
          <v-card-subtitle>
            Select a extend period
          </v-card-subtitle>
          <v-card-text>
            <v-radio-group v-model="extendRadio" mandatory>
              <v-radio
                label="Month"
                value="month"
              />
              <v-radio
                label="Year"
                value="year"
              />
            </v-radio-group>
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-space-between">
            <v-btn depressed class="text-capitalize" @click="closeExtend">
              Cancel
            </v-btn>
            <v-btn
              :loading="extendLoading"
              depressed
              color="primary"
              class="text-capitalize"
              @click="submitExtend"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <vue-snotify />
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PhoneNumbersOld',
  data: () => {
    return {
      selectAll: false,
      releaseRadio: null,
      releaseDialog: false,
      extendNumber: null,
      extendRadio: null,
      extendDialog: false,
      extendLoading: false,
      itemsPerPage: 50,
      page: 1,
      selectedItems: [],
      releaseNumber: null,
      items: [],
      loading: false,
      queries: {
        order_dir: 'DESC',
        order_by: 'createdOn',
        offset: 0,
        limit: 10
      },
      pageTotal: 50,
      pageTotalItems: [
        50,
        100,
        200
      ]
    }
  },
  watch: {
    selectAll (val) {
      if (val) {
        this.items.forEach(e => {
          e.isSelected = true
        })

        this.selectedItems = this.items
      } else {
        this.items.forEach(e => {
          e.isSelected = false
        })
        this.selectedItems = []
      }
    }
  },
  computed: {
    paginationList () {
      return this.items.slice((this.page - 1) * this.itemsPerPage, this.itemsPerPage * this.page)
    },
    countPage () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    }
  },
  created () {
    this.numbersList()
  },
  methods: {
    async numbersList () {
      this.loading = true

      await this.$store
        .dispatch('didNumbersList', this.queries)
        .then((res) => {
          this.items = res.data.didList

          this.loading = false
        }).catch(err => {
          console.log('err', err)
        })
    },
    formatDate: function (val, format = 'YYYY-MM-DD HH:mm:ss') {
      return val ? moment(val).utc().format(format) : ''
    },
    async submitRelease () {
      try {
        const payload = this.releaseNumber ? [this.releaseNumber] : this.selectedItems.map(item => item.number)
        const { success } = await this.$store.dispatch('releaseNumber', payload)

        if (success) {
          this.$snotify.notifications = []
          this.$snotify.success('Successful')

          this.numbersList()
        }
      } catch (e) {
        this.$snotify.error(e)
        throw new Error(e)
      } finally {
        this.releaseNumber = null
        this.releaseDialog = false
      }
      this.releaseDialog = false
      console.log('submit release', this.releaseRadio)
    },
    async submitExtend () {
      try {
        this.extendLoading = true

        const numbers = this.extendNumber ? [{ tn: this.extendNumber }] : this.selectedItems.map(item => ({ tn: item.number }))
        const payload = {
          paymentType: 'stripe',
          numbers,
          durationUnit: this.extendRadio
        }

        const data = await this.$store.dispatch('extendNumber', payload)

        console.log(data)
        if (data) {
          window.location.replace(data.paymentUrl)
        }
      } catch (e) {
        this.$snotify.error(e)
        throw new Error(e)
      } finally {
        this.extendLoading = false
        this.extendNumber = null
        this.extendDialog = false
      }
      this.releaseDialog = false
      console.log('submit release', this.releaseRadio)
    },
    openReleaseDialog (num) {
      console.log(num, 'numm')
      this.releaseNumber = num
      this.releaseDialog = true
    },
    openExtendDialog (num) {
      this.extendNumber = num
      this.extendDialog = true
    },
    closeRelease () {
      this.releaseNumber = null
      this.releaseDialog = false
    },
    closeExtend () {
      this.extendNumber = null
      this.extendDialog = false
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page !== 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    },
    selectItem (item) {
      if (item.isSelected) {
        this.selectedItems.push(item)
        console.log(this.selectedItems)

        return
      }

      this.selectedItems = this.selectedItems.filter(data => data.id !== item.id)

      console.log(this.selectedItems)
    }
  }
}
</script>

<style scoped lang="scss">

  .main {
    max-width: 728px;
      margin-right: auto;
  }
.v-list-item__content {
  min-width: 150px !important;
}

.v-list-item {
  @include breakpoint(small) {
    min-height: auto;
    padding: 8px;
  }
}

.v-list-item__action {
  @include breakpoint(small) {
    width: 100%;
  }

  .v-btn {
    width: 45%;
  }
}

.v-card__action>.v-btn {
  width: 50%;
}

.page-select {
  max-width: 20%;
}
</style>
